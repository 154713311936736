<template>
	<div class="main">
		<div class="heard">
			<div class="heardLeft">
				<div class="heardLeftTop">
					<div class="myHeard">
						<div class="myHeardImg">
							<div class="myHeardImg1">
								<img class="myImg" src="../../public/image/elePrice.png" alt="" />
								<span class="imgText">电费</span>
							</div>
							<span v-show="totleData.leftEleShow" class="myImgText myImgTextRed" >预计还能使用{{totleData.leftEle}}天</span>
						</div>
						<div class="money" :key="totleData.balanceEle">{{totleData.balanceEle}}</div>
						<div class="money moneyText">当前余额（元）</div>
					</div>
					<div class="addMoney" @click="recharge(1,'充电费')">充值</div>
				</div>
				<div class="heardLeftTop marginTop10">
					<div class="myHeard">
						<div class="myHeardImg">
							<div class="myHeardImg1">
								<img class="myImg" src="../../public/image/homePrice.png" alt="" />
								<span class="imgText">房租</span>
							</div>
							<span class="myImgText myImgTextRed" v-if="!totleData.leftRent">余额不足1个月</span>
						</div>
						<div class="money" :key="totleData.balanceRent">{{totleData.balanceRent}}</div>
						<div class="money moneyText">当前余额（元）</div>
					</div>
					<div class="addMoney addMoneyFf" @click="recharge(3,'充房租费')">充值</div>
				</div>
			</div>
			<div class="heardRight">
				<div class="errorHeard echarts">
					<div class="myHeardImg myHeardImg3">
						<div class="myHeardImg1">
							<div class="myPoint myPoint1"></div>
							<span class="myPointNum myPointNum1">最近15天用电量趋势（kWh）</span>
						</div>
						<div class="errorMore" @click="getMore(4)">更多</div>
					</div>
					<div class="tableDiv1" id="echarts1"></div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="heardLeft">
				<div class="heardLeftTop">
					<div class="myHeard">
						<div class="myHeardImg">
							<div class="myHeardImg1">
								<img class="myImg" src="../../public/image/eitPrice.png" alt="" />
								<span class="imgText">物业费</span>
							</div>
							<span class="myImgText myImgTextRed" v-if="!totleData.leftProp">余额不足1个月</span>
						</div>
						<div class="money" :key="totleData.balanceProp">{{totleData.balanceProp}}</div>
						<div class="money moneyText">当前余额（元）</div>
					</div>
					<div class="addMoney addMoneyWyf" @click="recharge(2,'充物业费')">充值</div>
				</div>
				<div class="heardLeftTop marginTop20">
					<div class="errorHeard">
						<div class="myHeardImg myHeardImg2">
							<div class="myHeardImg1">
								<div class="myPoint"></div>
								<span class="myPointNum">网银异常：{{unOne.time}}</span>
							</div>
							<div class="errorMore" v-if="unOne.time&&unOne.time>0" @click="getMore(3)">更多</div>
						</div>
					</div>
					<div v-if="unOne.time&&unOne.time>0">
						<div class="errorText">交易金额：<span class="errorText1">{{unOne.amount}}元</span></div>
						<div class="errorText errorText2">交易时间：<span class="errorText3">{{unOne.transDate}}</span></div>
						<div class="errorText errorText2">交易流水号：<span class="errorText3">{{unOne.serial}}</span></div>
						<div class="errorText errorText2">异常内容：<span class="errorText3">{{unOne.unusualMemo}}</span></div>
						<div class="errorBotton">
							<div class="myBotton" @click="returnCome(1)">转充电费</div>
							<div class="myBotton" @click="returnCome(3)">转充房租</div>
							<div class="myBotton" @click="returnCome(2)">转充物业费</div>
						</div>
					</div>
					<div v-else>
						<img class="noPromise" src="../../public/image/zwyc.png" alt="" />
					</div>
				</div>
			</div>
			<div class="bottomRight">
				<div class="errorHeard mySelf">
					<div class="myHeardImg myHeardImg4">
						<div class="myHeardImg1">
							<div class="myPoint myPoint2"></div>
							<span class="myPointNum myPointNum1">最近10次充值</span>
						</div>
						<div class="errorMore" @click="getMore(2)">更多</div>
					</div>
					<Table class="myTable" stripe :columns="columns" :data="data"></Table>
				</div>
			</div>
			<div class="bottomRight">
				<div class="errorHeard mySelf">
					<div class="myHeardImg myHeardImg4">
						<div class="myHeardImg1">
							<div class="myPoint myPoint2"></div>
							<span class="myPointNum myPointNum1">故障报修记录</span>
						</div>
						<div class="errorMore" @click="getMore(1)">更多</div>
					</div>
					<Table class="myTable" stripe :columns="columns1" :data="data1"></Table>
				</div>
			</div>
		</div>
		<Modal
			v-model="modify"
			:title="headText"
			class="modalHeard modalHeard1"
			@on-cancel="cancel"
			>
			<Form ref='form' :model="form" :label-width="100">
				<Form-item label="充值方式：">
					<RadioGroup v-model="form.type">
						<Radio label="0">微信支付</Radio>
						<Radio label="1">支付宝支付</Radio>
					</RadioGroup>
				</Form-item>
				<Form-item label="选择面值：">
					<div class="father">
						<div @click="choose(1,200)" class="chilren chilren1">200元<img v-if="myItem===1" class="moneyImg" src="../../public/image/checkout1.png" alt="" /></div>
						<div @click="choose(2,500)" class="chilren">500元<img v-if="myItem===2" class="moneyImg moneyImg1" src="../../public/image/checkout1.png" alt="" /></div>
						<div @click="choose(3,1000)" class="chilren">1000元<img v-if="myItem===3" class="moneyImg moneyImg2" src="../../public/image/checkout1.png" alt="" /></div>
						<div @click="choose(4,2000)" class="chilren">2000元<img v-if="myItem===4" class="moneyImg moneyImg3" src="../../public/image/checkout1.png" alt="" /></div>
					</div>
				</Form-item>
				<Form-item label="充值金额：">
					<i-input @on-change="changeMoney" v-model="form.amount">
						<span slot="append">元</span>
					</i-input>
					<div class="showImg" @click="showImg">确认</div>
				</Form-item>
				<div class="wechatImg" v-if="showEw">
					<div class="qrcode" ref="qrCodeUrl"></div>
					<div class="codeText" v-if="form.type=='0'">
						<img class="wechat" src="../../public/image/wechat.png" alt="" />
						<div class="wechatText">微信扫码支付</div>
					</div>
					<div class="codeText" v-else>
						<img class="wechat" src="../../public/image/zfb.png" alt="" />
						<div class="wechatText">支付宝扫码支付</div>
					</div>
				</div>
			</Form>
		</Modal>
		<myModal ref="mychild" :mytemp="temp"></myModal>
		<Modal v-model="rechargeModal" :title="rechargeName" @on-cancel="cancel1" class="modalHeard">
            <div class="errorText">交易金额：<span class="errorText1">{{unOne.amount}}元</span></div>
			<div class="errorText errorText2">交易时间：<span class="errorText3">{{unOne.transDate}}</span></div>
			<div class="errorText errorText2">交易流水号：<span class="errorText3">{{unOne.serial}}</span></div>
			<div class="errorText errorText2">异常内容：<span class="errorText3">{{unOne.unusualMemo}}</span></div>
            <div slot="footer" class="footer">
				<Button type="success" class="sure" @click="handleSubmit()">确定</Button>
				<Button type="primary" class="cancel" @click="cancel1()">取消</Button>
			</div>
        </Modal>
	</div>
</template>

<script>
	import url from '@/utils/api.js';
	import QRCode from 'qrcodejs2';
	import bus from "@/utils/bus.js";
	import myModal from "@/components/myModal"
	export default {
		name: "idnex",
		data() {
			return {
				totleData:{
					"areaName": "",
					"balanceEle": null,
					"balanceProp": null,
					"balanceRent": null,
					"bareaId": "",
					"buserId": "",
					"incomeBalance": null,
					"incomeCount": null,
					'leftEle':null,
					'leftProp':	Boolean,
					'leftRent':	Boolean,
					"name": "",
					"no": "",
					"refundBalance": null,
					"refundCount": null,
					"reversalBalance": null,
					"reversalCount": null,
					"romNo": ""
				},
				unOne:{},
				myItem:null,
				showEw:false,
				form:{
					type:'0',
					amount:null,
					accountType:null
				},
				modify:false,
				headText:'',
				columns:[{
                        title: '充值时间',
                        key: 'createTime'
                    },
                    {
                        title: '充值方式',
						render:(h,params)=>{
							return h('div', params.row.comeFrom==1?'现金':params.row.comeFrom==2?'银行转账':params.row.comeFrom==3?'微信':params.row.comeFrom==4?'支付宝':'')
							//0-系统 1-人工 2-银行转账 3-微信 4-支付宝
						}
                    },
                    {
                        title: '金额',
                        key: 'amount'
                    }
				],
				columns1:[
					{
                        title: '报修时间',
                        key: 'createTime'
                    },
                    {
                        title: '报修内容',
                        key: 'content'
                    },
                    {
                        title: '状态',
						render: (h, params) => {
							return h('div', [
                                h('div', {
                                    style: {
                                        color: params.row.state==0?'#f75050':'#6b6f89',
                                    }
                                },params.row.state==0?'未受理':'已受理'),
                            
                            ])
                        }
                    }
				],
				data:[],
				data1:[],
				temp:null,
				rechargeModal:false,
				rechargeTemp:null,
				rechargeName:'',
				qrcode:{},
				myInterval:null,
			}
		},
		computed: {

		},
		created() {
			
		},
		mounted() {
			this.getData()   //获取各种余额
			this.getRepair()  //获取报修记录列表
			this.inCome()     //获取充值记录列表
			this.getUnusual()  //获取充值异常列表
			this.getReport()  //获取用电量
			let that = this
			bus.$on('getNewList',function(){
				that.getRepair()
            })
		},
		methods: {
			getReport(){   //获取用电量
				this.$post(url.ENERGY_REPORT).then(res=>{
					let arr1 = []
					let arr2 = []
					if(res&&res.length>0){
						res.map(el=>{
							arr1.push(el.time)
							arr2.push(el.total)
						})
					}
					this.setEcharts(arr1,arr2,'echarts1',16)
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
			handleSubmit(){  //转充提交
				let opsAccountRecordReq = {
					'accountType':this.rechargeTemp,
					'amount':this.unOne.amount,
					'id':this.unOne.id
				}
				this.$post(url.RECHARGE,opsAccountRecordReq).then(()=>{
					this.$Message.success('转充成功！');
					this.cancel1()
					this.getUnusual()
					this.getData()
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
			},
			cancel1(){  //转充关闭弹窗
				this.rechargeModal = false,
                this.rechargeTemp = null,
                this.rechargeName = ''
			},
			getMore(value){ //点击更多 1-故障 2-充值 3-异常 4-电量
				this.$refs.mychild.myIncome(value);
			},
			returnCome(value){  //故障转充
				this.rechargeModal = true
                this.rechargeTemp = value
                if(value===1){
                    this.rechargeName = '转充电费'
                }else if(value===2){
                    this.rechargeName = '转充物业费'
                }else{
                    this.rechargeName = '转充房租'
                }
			},
			showImg(){  //确定金额显示二维码
				window.clearInterval(this.myInterval);
				if(this.form.amount){
					this.showEw = true
					let payReq = {
						accountType : this.form.accountType,
						amount: this.form.amount
					}
					this.$post(url.WECHAT_PAY,payReq).then(res=>{
						if(res&&res.orderId&&res.code_url){
							if(this.$refs.qrCodeUrl){
								this.$refs.qrCodeUrl.innerHTML = ''
							}
							this.qrcode = new QRCode(this.$refs.qrCodeUrl, { // eslint-disable-line no-unused-vars
								text: res.code_url, // 需要转换为二维码的内容
								width: 120,
								height: 120,
								colorDark: '#000000',
								colorLight: '#ffffff',
								correctLevel: QRCode.CorrectLevel.H
							})
							this.myInterval = window.setInterval(() => {
								this.getReturn(res.orderId)
							}, 1000);
						}
					}).catch(error=>{
						this.common.tokenIsTrue(this,error);
					})
				}else{
					this.$Message.warning('充值金额不能为空！');
				}
			},
			getReturn(value){ //支付回调
				this.$get(url.PAY_RETURN+'?orderId='+value).then(res=>{
					if(res===0){
						this.$Message.success('支付成功！');
						if(this.form.accountType==1){
							this.totleData.balanceEle = this.totleData.balanceEle*1 + this.form.amount*1
						}else if(this.form.accountType==2){
							this.totleData.balanceProp = this.totleData.balanceProp*1 + this.form.amount*1
						}else if(this.form.accountType==3){
							this.totleData.balanceRent = this.totleData.balanceRent*1 + this.form.amount*1
						}
						this.inCome()  //充值记录列表
						this.cancel()
					}
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
			},
			cancel(){  //充值关闭弹窗
				this.headText = ''
				window.clearInterval(this.myInterval);
				this.showEw = false
				this.modify = false
				this.form.accountType = null
				this.form.type = '0'
				this.form.amount = null
				this.myItem = null
			},
			choose(temp,value){  //选择器选择 回显Input
				this.myItem = temp
				this.form.amount = value
			},
			changeMoney(event){    //充值修改Input 回显选择器
				if(event.target.value=='200'||event.target.value=='500'||event.target.value=='1000'||event.target.value=='2000'){
					switch (event.target.value) {
						case '200':
							this.myItem = 1
							break;
						case '500':
							this.myItem = 2
							break;
						case '1000':
							this.myItem = 3
							break;
						case '2000':
							this.myItem = 4
							break;
					}
				}else{
					this.myItem = null
				}
			},
			recharge(temp,text){  //充值显示弹窗
				this.headText = text
				this.modify = true
				this.form.accountType = temp
			},
			getData(){   //获取费用余额
				this.$get(url.INDEX_DATE).then(res=>{
					this.totleData = res
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
			setEcharts(value1=[],value2=[],value3='',value4=null) { //echarts渲染
				var echarts = require('echarts');
				this.myChart = echarts.init(document.getElementById(value3));
				this.myChart.setOption({
					color: ['#3398DB'],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '10px',
						right: '10px',
						bottom: '5px',
                        top:'10px',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: value1,
						axisTick: {
							alignWithLabel: true
						}
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						type: 'bar',
						barWidth: value4,
						itemStyle: {
							normal: {
								barBorderRadius: value4/2,
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: "#0ccec3"
								}, {
									offset: 1,
									color: "#4b92f2"
								}], false)
							}
						},
						data: value2
					}]
				})
			},
			getRepair(){   //报修信息列表
				let opsRepairInfoReq = {
					size : 10,
					current : 1
				}
				this.$post(url.REPORT_LIST,opsRepairInfoReq).then(res=>{
					this.data1 = res.records
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
			inCome(){    //充值记录列表
				let opsAccountRecordReq = {
					size : 10,
					current : 1
				}
				this.$post(url.IN_COME,opsAccountRecordReq).then(res=>{
					this.data = res.records
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
			getUnusual(){   //充值异常列表
				let opsAccountRecordReq = {
					size : 10,
					current : 1
				}
				this.$post(url.UN_USUAL,opsAccountRecordReq).then(res=>{
					if(res&&res.records&&res.records.length>0){
						this.unOne = res.records[0]
					}
					this.unOne['time'] = res.total
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			}
		},
		components: {
			myModal
		}

	}
</script>
<style lang="less">
	.myTable>.ivu-table{
		.ivu-table-header>table>thead>tr>th{
			background-color: #e6faf9;
			color: #0ccec3;
			border-bottom: none;
			font-size: 14px;
		}
		.ivu-table-body>table>.ivu-table-tbody>tr>td{
			height: 32px;
			border-bottom: none;
			color: #6b6f89;
			.ivu-table-cell>span{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.modalHeard1>.ivu-modal-wrap>.ivu-modal{
		width:720px !important;
		height:520px;
		.ivu-modal-content{
			.ivu-modal-body>form>.ivu-form-item{
				.ivu-form-item-content{
					display:flex;
					.ivu-input-wrapper{
						width:330px;
						.ivu-input{
							height:36px;
						}
					}
				}
			}
			.ivu-modal-footer{
				display:none
			}
		}
	}
	.modalHeard2>.ivu-modal-wrap>.ivu-modal{
		width:840px !important;
		height:560px;
		.ivu-modal-content{
			.ivu-modal-footer{
				display:none
			}
		}
	}
	
</style>
<style scoped lang="less">
	.main {
		width: 1440px;
		height: 880px;
		margin:20px auto;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
	}
	.heard{
		width:1440px;
		height:420px;
		display:flex;
		justify-content:space-between;
	}
	.heardLeft{
		width:360px;
		height:100%;
	}
	.heardLeftTop{
		width:100%;
		height:200px;
		background:#fff;
		border-radius: 8px;
	}
	.marginTop10{
		margin-top:20px;
	}
	.marginTop20{
		margin-top:20px;
		height:220px;
	}
	.heardRight{
		width:1060px;
		height:420px;
		background:#fff;
		border-radius: 8px;
	}
	.bottom{
		margin-top:20px;
		width:1440px;
		height:440px;
		display:flex;
		justify-content:space-between;
	}
	.bottomRight{
		width:520px;
		height:440px;
		background:#fff;
		border-radius: 8px;
	}
	.myHeard{
		width:100%;
		height:140px;
		border-top:1px solid #fff;
		border-bottom:1px solid #f0f3f8;
	}
	.myHeardImg{
		width:335px;
		height:26px;
		margin:14px auto 0 10px;
		display:flex;
		justify-content:space-between;
	}
	.myHeardImg1{
		display:flex;
		align-items:center;
	}
	.myHeardImg2{
		width:330px;
		height:24px;
		margin:13px auto 0 20px;
	}
	.myHeardImg3{
		width:1020px;
		height:24px;
		margin:13px auto;
	}
	.myHeardImg4{
		width:480px;
		height:24px;
		margin:13px auto;
	}
	.myImg{
		width:26px;
		height:26px;
	}
	.imgText{
		font-family: SourceHanSansCN-Medium;
		font-size: 16px;
		line-height: 26px;
		color: #44475d;
		margin-left:9px;
	}
	.myImgText{
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 26px;
		color: #6b6f89;
	}
	.myImgTextRed{
		color:red;
	}
	.money{
		width:100%;
		height:23px;
		margin-top:31px;
		text-align:center;
		font-family: ArialMT;
		font-size: 32px;
		line-height: 23px;
		color: #44475d;
	}
	.moneyText{
		height:13px;
		margin-top:14px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 13px;
		color: #6b6f89;
	}
	.addMoney{
		width:100px;
		height:30px;
		margin:14px auto;
		background-color: #8867f2;
		border-radius: 15px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 30px;
		color: #ffffff;
		cursor:pointer;
	}
	.addMoneyFf{
		background-color: #4b92f2;
	}
	.addMoneyWyf{
		background-color: #0ccec3;
	}
	.errorHeard{
		width:100%;
		height:50px;
		border-top:1px solid #fff;
		border-bottom:1px solid #f0f3f8;
	}
	.echarts{
		border-bottom:1px solid #e2e7f1;
	}
	.mySelf{
		border-bottom:none
	}
	.myPoint{
		width:6px;
		height:6px;
		background:#f75050;
		border-radius:3px;
	}
	.myPoint1{
		background:#4b92f2;
	}
	.myPoint2{
		background:#0ccec3;
	}
	.myPointNum{
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 24px;
		color: #44475d;
		margin-left:7px;
	}
	.myPointNum1{
		font-family: SourceHanSansCN-Regular;
	}
	
	.errorMore{
		width: 60px;
		height: 24px;
		background-color: #ffffff;
		border-radius: 12px;
		border: solid 1px #bcc9dc;
		font-family: SourceHanSansCN-Regular;
		font-size: 14px;
		line-height: 24px;
		color: #858693;
		cursor:pointer;
	}
	.errorText{
		margin-top:11px;
		width:auto;
		height:12px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 12px;
		color: #44475d;
		text-align:left;
		margin-left:30px;
	}
	.errorText1{
		color:#0ccec3;
	}
	.errorText2{
		margin-top:18px;
	}
	.errorText3{
		color:#858693;
	}
	.errorBotton{
		margin:16px auto 0 auto;
		width:310px;
		height:30px;
		display:flex;
		justify-content:space-between;
	}
	.myBotton{
		width: 100px;
		height: 30px;
		background-color: #0ccec3;
		border-radius: 15px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 30px;
		color: #ffffff;
		cursor:pointer;
	}
	.tableDiv1 {
		width: 1020px;
		height: 320px;
		margin: 30px auto 0 auto;
	}
	.myTable{
		width: 500px;
    	margin: 0 auto;
	}
	.father{
		display:flex;
	}
	.chilren{
		margin-left:10px;
		width: 140px;
		height: 36px;
		background-color: #ffffff;
		border-radius: 4px;
		border: solid 1px #0ccec3;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #0ccec3;
		text-align:center;
		cursor:pointer;
	}
	.chilren1{
		margin-left:0px;
	}
	.moneyImg{
		width:35px;
		height:28px;
		position: absolute;
		left: 105px;
		top: 8px;
	}
	.moneyImg1{
		left: 255px;
	}
	.moneyImg2{
		left: 405px;
	}
	.moneyImg3{
		left: 555px;
	}
	.showImg{
		width: 80px;
		height: 36px;
		background-color: #0ccec3;
		border-radius: 4px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		margin-left:10px;
		text-align:center;
		cursor:pointer;
	}
	.wechatImg{
		margin-left:100px;
		// border:1px solid
		margin-bottom:160px;
	}
	.qrcode{
		display: inline-block;
		img {
			width: 120px;
			height: 120px;
			background-color: #fff; //设置白色背景色
			padding: 6px; // 利用padding的特性，挤出白边
			box-sizing: border-box;
		}
	}
	.codeText{
		display:flex;
	}
	.wechatText{
		font-family: MicrosoftYaHei;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #44475d;
		margin-left:9px;
	}
	.wechat{
		width:21px;
		height:18px;
	}
	.footer{
		text-align: center;
	}
    .sure{
		width: 80px;
		height: 32px;
		background-color: #0ccec3;
		border-radius: 4px;
		border-color:#0ccec3;
	}
	.cancel{
		width: 80px;
		height: 32px;
		background-color: #abb2c0;
		border-radius: 4px;
		border-color:#abb2c0;
	}
	.noPromise{
		width:70px;
		height:67px;
		margin-top:20px;
	}
</style>
