<template>
	<div class="add export" @click="exportExcel"><img src="../../public/image/export.png" class="logo">
        <span class="addText">导出</span>
    </div>
</template>

<script>
	export default {
		name: "export",
		data() {
			return {
         
            }
		},
        props:{
            listData:{
                type: Array
            },
            columns:{
                type: Array
            },
            name:{
                type: String
            }
        },
        created() {

		},
        mounted(){
        },
		methods: {
            exportExcel(){
                setTimeout(() => {
                    let allColumns = this.columns
                    var columnNames = []
                    var columnValues = []
                    for (var i = 0; i < allColumns.length; i++) {
                        columnNames[i] = allColumns[i].title
                        columnValues[i] = allColumns[i].key
                    }
                    if (this.listData.length > 0) {
                        require.ensure([], () => {
                            const { export_json_to_excel} = require('@/vendor/Export2Excel')
                            const tHeader = columnNames
                            const filterVal = columnValues
                            const list = this.listData
                            const data = this.formatJson(filterVal, list)
                            let nameexl =  this.name
                            export_json_to_excel(tHeader, data, nameexl)
                        })
                    }
                }, 2000)
            },

            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => v[j]))
            }
		},
		computed: {

		},
		components: {

		}
	}
</script>

<style scoped>
    .addText {
        margin-left: 5px;
    }
</style>
