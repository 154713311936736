<template>
    <Modal
        v-model="repair"
        :title="temp===1?'故障报修记录':temp===2?'充值记录':temp===3?'网银异常':temp===4?'用电量':''"
        class="modalHeard modalHeard2"
        @on-cancel="cancel"
        >
        <Form ref='form' class="myForm" :model="form" inline :label-width="85">
            <Form-item v-if="temp!==3" class="myLabel" label="开始日期：">
                <DatePicker v-model="form.startTime" type="date" style="width: 160px"></DatePicker>
            </Form-item>
            <Form-item v-if="temp!==3" class="myLabel" label="结束日期：">
                <DatePicker v-model="form.endTime" type="date" style="width: 160px"></DatePicker>
            </Form-item>
            <Form-item v-if="temp===1" class="myLabel myLabel1"  label="状态：">
                <Select v-model="form.state" clearable  style="width:160px">
                    <Option value="0">未处理</Option>
                    <Option value="1">已处理</Option>
                </Select>
            </Form-item>
            <div v-if="temp!==3" class="add modify" @click="getList(true)"><img src="../../public/image/search.png" class="logo"><span class="addText">查询</span></div>
            <Export v-if="temp===2||temp===4" :listData="data" :columns="temp===2?columns2:temp===4?columns4:''" :name="temp===2?excelName:excelName1"></Export>
            <div v-if="temp===3" class="add myBtn" @click="returnCome(1)">转充电费</div>
            <div v-if="temp===3" class="add modify" @click="returnCome(3)">转充房租</div>
            <div v-if="temp===3" class="add export" @click="returnCome(2)">转充物业费</div>
        </Form>
        <Table border height="400" @on-row-click="clickRow" @on-selection-change="selectList" ref="myTable" id="myTable" class="mySelfTable" :columns="temp===1?columns1:temp===2?columns2:temp===3?columns3:columns4" :data="data">
            <template v-if="temp===1" slot-scope="{ row }" slot="imgaction">
                <img class="myImg" :src="row.photo.split(';').length>0?baseURL1+row.photo.split(';')[0]:baseURL1+row.photo" v-if="row.photo.split('png').length-1>0" @click="handleView(row.photo)">
                共{{row.photo.split('png').length-1}}张
            </template>
        </Table>
        <Page v-if="temp!==4" class="myPage" :total="total" :page-size="size" :current="current" show-sizer show-elevator @on-page-size-change="sizeChange" @on-change="onChange" />
        <Modal :title="imgName" v-model="visible">
            <img :src="imgName" style="width: 100%">
        </Modal>
        <Modal v-model="recharge" :title="rechargeName" @on-cancel="cancel1" class="modalHeard">
            <div class="errorText">交易金额：<span class="errorText1">{{sel[0].amount}}元</span></div>
			<div class="errorText errorText2">交易时间：<span class="errorText3">{{sel[0].transDate}}</span></div>
			<div class="errorText errorText2">交易流水号：<span class="errorText3">{{sel[0].serial}}</span></div>
			<div class="errorText errorText2">异常内容：<span class="errorText3">{{sel[0].unusualMemo}}</span></div>
            <div slot="footer" class="footer">
				<Button type="success" class="sure" @click="handleSubmit()">确定</Button>
				<Button type="primary" class="cancel" @click="cancel1()">取消</Button>
			</div>
        </Modal>
    </Modal>
    
</template>

<script>
	import url from '../utils/api.js';
    import baseURL from '@/utils/env.js';
    import Export from '@/components/export';
	export default {
		name: "myModal",
		data() {
			return {
                baseURL1:baseURL,
                repair: false,
                headText:'',
                recharge:false,
                rechargeTemp:null,
                rechargeName:'',
                visible:false,
                excelName:'充值记录',
                excelName1:'用电量',
                imgName:'',
                sel:[{
                    'amount':'',
                    'transDate':'',
                    'serial':'',
                    'unusualMemo':''
                }],
                temp:null,
                form:{
                    state:'',
                    startTime:'',
                    endTime:'',
                },
                size:10,
                current:1,
                total:null,
                data:[],
                columns1:[
                    {
                        title: '报修时间',
                        key: 'createTime',
                        width: 170
                    },
                    {
                        title: '报修内容',
                        key: 'content'
                    },
                    {
                        title: '图片',
                        slot: 'imgaction',
                        width:100,
                    },
                    {
                        title: '状态',
                        width:100,
						render: (h, params) => {
							return h('div', [
                                h('div',params.row.state==0?'未受理':'已受理'),
                            ])
                        }
                    }
                ],
                columns2:[
                    {
                        title: '充值时间',
                        key: 'createTime'
                    },
                    {
                        title: '充值方式',
                        key:'payType'
                    },
                    {
                        title: '金额',
                        key: 'amount'
                    }
                ],
                columns3:[
                    {
                        type: 'selection',
                        width: 53
                    },
                    {
                        title: '交易时间',
                        key: 'transDate'
                    },
                    {
                        title: '交易金额（元）',
                        key:'amount'
                    },
                    {
                        title: '交易流水号',
                        key: 'serial'
                    },
                    {
                        title: '异常内容',
                        key: 'unusualMemo'
                    }
                ],
                columns4:[
                    {
                        title: '日期',
                        key:'time'
                    },
                    {
                        title: '用电量（kWh）',
                        key: 'total'
                    }
                ]
			}
		},
        props:{
            mytemp:{
                type: Number
            }
        },
		computed: {

		},
		mounted: function() {

		},
		methods: {
            handleSubmit(){
                let opsAccountRecordReq = {
					'accountType':this.rechargeTemp,
					'amount':this.sel[0].amount,
					'id':this.sel[0].id
				}
				this.$post(url.RECHARGE,opsAccountRecordReq).then(()=>{
                    this.$Message.success('转充成功！');
                    this.data.map((el,index)=>{
                        if(el.id == this.sel[0].id){
                            this.data.splice(index,1)
                        }
                    })
                    this.cancel1()
                    this.$parent.getData();
				}).catch(error=>{
					this.common.tokenIsTrue(this,error);
				})
            },
            cancel1(){
                this.recharge = false,
                this.rechargeTemp = null,
                this.rechargeName = ''
            },
            clickRow(row,index){
				this.$refs.myTable.toggleSelect(index);
			},
            selectList(value){
                if(value&&value.length > 0){
                    this.sel = value
                }else{
                    this.sel = []
                }
            },
            returnCome(value){
                if(this.sel&&this.sel[0].id){
					if(this.sel&&this.sel.length == 1){
                        this.recharge = true
                        this.rechargeTemp = value
                        if(value===1){
                            this.rechargeName = '转充电费'
                        }else if(value===2){
                            this.rechargeName = '转充物业费'
                        }else{
                            this.rechargeName = '转充房租'
                        }
					}else{
						this.$Message.warning('只能转充一项异常!');
					}
				}else{
					this.$Message.warning('请选择一项异常!');
				}
            },
            sizeChange(value){
                this.size = value
                this.getList()
            },
            onChange(value){
                this.current = value
                this.getList()
            },
            getList(value){
                if(this.temp === 1){
                    this.getReportList(value)
                }else if(this.temp === 2){
                    this.inCome(value)
                }else if(this.temp === 3){
                    this.getUnusual(value)
                }else if(this.temp === 4){
                    this.form.startTime =  this.form.startTime?this.form.startTime:new Date(new Date() - 1000 * 60 * 60 * 24 * 30)
                    this.form.endTime = this.form.endTime?this.form.endTime:new Date()
                    this.getReport()
                }
            },
            getReport(){
                let opsAccountRecordReq = {
                    'startTime':this.form.startTime?this.$moment(this.form.startTime).format('YYYY-MM-DD'):'',
					'endTime':this.form.endTime?this.$moment(this.form.endTime).format('YYYY-MM-DD'):''
				}
                this.$post(url.ENERGY_LIST,opsAccountRecordReq).then(res=>{
                    this.data = res
                }).catch(error=>{
                    this.common.tokenIsTrue(this,error);
                })
            },
            myIncome(value){
                this.repair = true
                this.temp = value
                this.getList()
            },
            getUnusual(){   //充值异常列表
				let opsAccountRecordReq = {
					size : this.size,
					current : this.current
				}
				this.$post(url.UN_USUAL,opsAccountRecordReq).then(res=>{
					this.data = res.records
                    this.total = res.total
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
            inCome(value){    //充值记录列表
				let opsAccountRecordReq = {
					'size' : value?10:this.size,
					'current' : value?1:this.current,
                    'startTime':this.form.startTime?this.$moment(this.form.startTime).format('YYYY-MM-DD 00:00:00'):'',
					'endTime':this.form.endTime?this.$moment(this.form.endTime).format('YYYY-MM-DD 23:59:59'):''
				}
				this.$post(url.IN_COME,opsAccountRecordReq).then(res=>{
                    if(res&&res.records&&res.records.length>0){
                        res.records.map(el=>{
                            el['payType'] = el.comeFrom==1?'现金':el.comeFrom==2?'银行转账':el.comeFrom==3?'微信':'支付宝'
                        })
                    }
					this.data = res.records
                    this.total = res.total
				}).catch(error => {
					this.common.tokenIsTrue(this,error);
                })
			},
            getReportList(value){  //故障
                let opsRepairInfoReq = {
					'size' : value?10:this.size,
					'current' : value?1:this.current,
                    'startTime':this.form.startTime?this.$moment(this.form.startTime).format('YYYY-MM-DD 00:00:00'):'',
					'endTime':this.form.endTime?this.$moment(this.form.endTime).format('YYYY-MM-DD 23:59:59'):'',
                    'state':this.form.state
				}
                this.$post(url.REPORT_LIST,opsRepairInfoReq).then(res=>{
                    this.data = res.records
                    this.total = res.total 
                }).catch(error=>{
                    this.common.tokenIsTrue(this,error);
                })
            },
			cancel(){
                if(this.temp === 3){
                    this.$parent.getUnusual()
                }
                this.repair = false,
                this.temp = null,
                this.data = [],
                this.total = null,
                this.form = {
                    state:'',
                    startTime:'',
                    endTime:'',
                },
                this.headText = '',
                this.visible = false,
                this.imgName = '',
                this.size = 10,
                this.current = 1
            },
            handleView(name) {
                if(name.split(';').length>0){
                    this.imgName = baseURL + name.split(';')[0];
                }else{
                    this.imgName = baseURL + name
                }
                this.visible = true;
            }
		},
        components: {
			Export
		}

	}
</script>
<style lang="less">
    .mySelfTable>.ivu-table{
        .ivu-table-body>table>.ivu-table-tbody>tr>td{
            height:36px;
            .ivu-table-cell{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .ivu-table-header>table>thead>tr>th{
            height:40px;
            background-color: #f0f3f8;
        }
    }
</style>
<style scoped lang="less">
    .myLabel1{
        margin-left:-30px;
    }
    .myLabel > .ivu-form-item-content>div{
        margin-left:-15px;
    }
    .add {
        display: inline-block;
        width: auto;
        height: 32px;
        background-color: #4b92f2;
        padding: 0 16px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 0px;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }
    .modify {
        background-color: #0ccec3;
        margin-left: 10px;
    }
    .export {
        background-color: #8867f2;
        margin-left: 10px;
    }
    .logo {
        width: 14px;
        height: 14px;
        margin-bottom: -2px;
    }
    .addText {
        margin-left: 5px;
    }
    .mySelfTable{
        margin-bottom: 10px;
        height:400px;
    }
    .myPage {
        text-align: right;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
    }
    .myBtn{
        margin-bottom:20px;
    }
    .errorText{
		margin-top:11px;
		width:auto;
		height:12px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 12px;
		color: #44475d;
		text-align:left;
		margin-left:30px;
	}
	.errorText1{
		color:#0ccec3;
	}
	.errorText2{
		margin-top:18px;
	}
	.errorText3{
		color:#858693;
	}
    .footer{
		text-align: center;
	}
    .sure{
		width: 80px;
		height: 32px;
		background-color: #0ccec3;
		border-radius: 4px;
		border-color:#0ccec3;
	}
	.cancel{
		width: 80px;
		height: 32px;
		background-color: #abb2c0;
		border-radius: 4px;
		border-color:#abb2c0;
	}
    .myImg{
        width:40px;
        height:30px;
    }
</style>

